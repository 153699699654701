<template>
<!-- 家庭健身 -->
    <div>
        <div class="posit-left">
            <familylefttop style="margin-bottom:0.18rem;"></familylefttop>
            <div id="family">
                <div>
                    <activetime style="margin-bottom:0.20rem;"></activetime>
                    <activeweek></activeweek>
                </div>
                <div>
                    <familysports></familysports>
                </div>
            </div>
        </div>
        <div class="posit-right">
            <useranalysis style="margin-bottom:0.18rem;"></useranalysis>
            <fitness></fitness>
        </div>
    </div>
</template>

<script>

export default {
    components: {
        familylefttop: () => import('../charts/familyfitness/familylefttop.vue'),
        activetime: () => import('../charts/familyfitness/activetime.vue'),
        activeweek: () => import('../charts/familyfitness/activeweek.vue'),
        familysports: () => import('../charts/familyfitness/familysports.vue'),
        useranalysis: () => import('../charts/familyfitness/useranalysis.vue'),
        fitness: () => import('../charts/familyfitness/fitness.vue'),
    }
}
</script>

<style scoped>
#family{
    display: flex;
    justify-content: space-between;
}
</style>